import React from "react"
import vedicSrijan from "../images/16thMarch2023Event/images/awardsWinners/vedicSrijan.jpg"
import functionalVocationalTRaining from "../images/16thMarch2023Event/images/awardsWinners/functionalVocationalTRaining.jpg"
import EntecresLabs from "../images/16thMarch2023Event/images/awardsWinners/EntecresLabs.jpg"
import TrendTechnologiesIndia from "../images/16thMarch2023Event/images/awardsWinners/TrendTechnologiesIndia.jpg"
import LinkapparelExport from "../images/16thMarch2023Event/images/awardsWinners/LinkapparelExport.jpg"
import Aspirelabs from "../images/16thMarch2023Event/images/awardsWinners/Aspirelabs.jpg"
import HeroSteel from "../images/16thMarch2023Event/images/awardsWinners/HeroSteel.jpg"
import Rubamin from "../images/16thMarch2023Event/images/awardsWinners/Rubamin.jpg"
import Michelin from "../images/16thMarch2023Event/images/awardsWinners/Michelin.jpg"

export default function awards2023() {
  return (
    <>
      <div className="container py-5">
        <h2 className="text-center mb-5 fw-bold">
          Indian ESG Network Awards 2023
        </h2>
        <h3 className="fw-bold mb-3 text Center">Award Categories</h3>

        <div className="row">
          <div className="col-md-12 mt-4">
            <h6 className="fw-bold">
              A. ESG Enabler of the Year (Organization)
            </h6>
            <div className="">
              The Indian ESG Network was established to promote awareness of ESG
              (environmental, social, and governance) issues in the Indian
              ecosystem. There is an alarming need to unite all ecosystems and
              encourage a shift from consumption to contribution. In line with
              this goal, the Indian ESG Network Awards were recently announced
              to recognise organisations that prioritise sustainability in their
              business operations, leadership, and overall approach.
            </div>
            <h6 className="fw-bold pt-5 mb-4">Recognition Goes to:</h6>
            <div>
              <div className="row text-center">
                <div className="col-md-4">
                  <img src={vedicSrijan} alt={``} />
                  <br />
                  <h6 className="fw-bold mt-3">Vedic Srijan</h6>
                </div>
                <div className="col-md-4">
                  <img src={functionalVocationalTRaining} alt={``} />
                  <br />
                  <h6 className="fw-bold mt-3">
                    Functional Vocational Training and Research Society
                  </h6>
                </div>
                <div className="col-md-4">
                  <img src={EntecresLabs} alt={``} />
                  <br />
                  <h6 className="fw-bold mt-3">Entecres Labs Pvt. Ltd</h6>
                </div>
              </div>
              <div className="mt-3">
                Who were felicitated for their accomplishments in the category
                "Best ESG Practice - Indian, Micro, Small & Medium Enterprises
                (MSME)" at the national launch of the Indian ESG Network on
                March 16, 2023, at the India Habitat Centre. We extend our
                congratulations to all of the organisations that have been
                nominated and recognised in various categories. We encourage
                them to share their best ESG practices' with the wider
                ecosystem.
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-5 pt-5">
            <h6 className="fw-bold">
              B. Best ESG Practice - Indian, Micro, Small & Medium Enterprises
              (MSME)
            </h6>
            <div className="">
              The Indian ESG Network was established to promote awareness of ESG
              (environmental, social, and governance) issues in the Indian
              ecosystem. There is an alarming need to unite all ecosystems and
              encourage a shift from consumption to contribution. In line with
              this goal, the Indian ESG Network Awards were recently announced
              to recognise organisations that prioritise sustainability in their
              business operations, leadership, and overall approach
            </div>

            <h6 className="fw-bold pt-5 mb-4">Recognition Goes to:</h6>
            <div className="row text-center">
              <div className="col-md-4">
                <img src={TrendTechnologiesIndia} alt={``} />
                <br />
                <h6 className="fw-bold mt-3">
                  Trend Technologies India Pune Pvt Ltd.
                </h6>
              </div>
              <div className="col-md-4">
                <img src={LinkapparelExport} alt={``} />
                <br />
                <h6 className="fw-bold mt-3">Linkapparel Export Pvt Ltd.</h6>
              </div>
              <div className="col-md-4">
                <img src={Aspirelabs} alt={``} />
                <br />
                <h6 className="fw-bold mt-3">
                  Aspirelabs Accelerator Pvt. Ltd.
                </h6>
              </div>
            </div>
            <div>
              <div className="mt-3">
                who were felicitated for their accomplishments in the category
                "Best ESG Practice - Indian, Micro, Small & Medium Enterprises
                (MSME)" at the national launch of the Indian ESG Network on
                March 16, 2023, at the India Habitat Centre. We extend our
                congratulations to all of the organisations that have been
                nominated and recognised in various categories. We encourage
                them to share their best ESG practices' with the wider
                ecosystem.
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-5 pt-5">
            <h6 className="fw-bold">
              C. Best ESG Practice - Large India Corporate / MNCs
            </h6>
            <div className="">
              The Indian ESG Network was established to promote awareness of ESG
              (environmental, social, and governance) issues in the Indian
              ecosystem. There is an alarming need to unite all ecosystems and
              encourage a shift from consumption to contribution. In line with
              this goal, the Indian ESG Network Awards were recently announced
              to recognise organisations that prioritise sustainability in their
              business operations, leadership, and overall approach.
            </div>
            <h6 className="fw-bold pt-5 mb-4">Recognition Goes to:</h6>
            <div className="row text-center">
              <div className="col-md-4">
                <img src={HeroSteel} alt={``} />
                <br />
                <h6 className="fw-bold mt-3">Hero Steel Limited</h6>
              </div>
              <div className="col-md-4">
                <img src={Rubamin} alt={``} />
                <br />
                <h6 className="fw-bold mt-3">Rubamin Private Limited</h6>
              </div>
              <div className="col-md-4">
                <img src={Michelin} alt={``} />
                <br />
                <h6 className="fw-bold mt-3">Michelin India Private Limited</h6>
              </div>
            </div>
            <div>
              <div className="mt-3">
                who were felicitated for their accomplishments in the category
                "Best ESG Practice - Indian, Micro, Small & Medium Enterprises
                (MSME)" at the national launch of the Indian ESG Network on
                March 16, 2023, at the India Habitat Centre. We extend our
                congratulations to all of the organisations that have been
                nominated and recognised in various categories. We encourage
                them to share their best ESG practices' with the wider
                ecosystem.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
